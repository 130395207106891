import React from "react";

import ContactForm from "../../../../ContactForm";

const StudiumContact = ({ name = "studium" }) => {
    return (
        <ContactForm
            urlEnding="studium"
            canSendStorageString={`${name}::contact-form:sent-at`}
            pushToGTMString={`${name}:contact-form`}
            scrollToAnchor="contact"
        />
    );
};

export default StudiumContact;
